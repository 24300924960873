const breakpoints = {
	sm: 575,
	md: 767,
	lg: 991,
	xl: 1199
};

export function isXS() {
	return window.matchMedia(`(max-width: ${breakpoints.sm}px)`).matches;
}

export function isTablet() {
	return window.matchMedia(`(min-width: ${breakpoints.md + 1}px) and (max-width: ${breakpoints.lg}px)`).matches;
}

export function isTabletAndBelow() {
	return window.matchMedia(`(max-width: ${breakpoints.md}px)`).matches;
}

export function isTabletAndAbove() {
  return window.matchMedia(`(min-width: ${breakpoints.md + 1}px)`).matches;
}

export function isDesktop() {
	return window.matchMedia(`(min-width: ${breakpoints.lg + 1}px)`).matches;
}

export function isDesktopAndBelow() {
    return window.matchMedia(`(max-width: ${breakpoints.lg}px)`).matches;
}

export function isDesktopXL() {
	return window.matchMedia(`(min-width: ${breakpoints.xl + 1}px)`).matches;
}
