export default class {
    init() {
        jQuery('body').on('click', '.form-contact__close', function(e) {
            jQuery(this).closest('.form-contact').removeClass('form-contact-open');
        });

        jQuery('body').on('click', '.button-section span', function(e) {
            jQuery('#form-contact').addClass('form-contact-open');
        });

        jQuery('body').on('click', '.open-form', function(e) {
            jQuery('#form-contact').addClass('form-contact-open');
            console.log(jQuery('#form-contact'));
        });

        jQuery('body').on('click', 'a[href="#open-form"]', function(e) {
            e.preventDefault();
            jQuery('#form-contact').addClass('form-contact-open');
        });

        jQuery(document).on("click", function(event){
            if(!jQuery(event.target).closest(".form-contact__wrapper, .open-form, .button-section .button, a[href='#open-form']").length){
                console.log(jQuery(event.target));
                jQuery('#form-contact').removeClass('form-contact-open');
            }
        });

    }
}
