/* eslint-disable */
/* Eslint is disable here because ScrollMagic has a problem with a ES6 compability. */

import ScrollToPlugin from "gsap/ScrollToPlugin";
import gsap from 'gsap';
import * as ScrollMagic from "scrollmagic";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
// import 'debug.addIndicators';
import {isDesktop} from "./Helpers/_mediaQueries";
import {getRandomInt, isMobileDevice} from "./Helpers/_helpers";
import {selectors} from "./_Header";

ScrollMagicPluginGsap(ScrollMagic, gsap);

export default class {

    smoothscroll() {
        const header = document.querySelector(selectors.header);
        const hamburger = document.querySelector(selectors.hamburger);
        const navigation = document.querySelector(selectors.navigation);
        const controllerSmooth = new ScrollMagic.Controller();
        const scrollPlugin = ScrollToPlugin;
        const height = document.querySelector('[data-js-header]').offsetHeight;


        /** ******** Begin Smooth Anchor Links ********** */
        controllerSmooth.scrollTo((newpos, speed) => {
            TweenMax.to(window, speed, {scrollTo: {y: newpos - height}});
        });

        jQuery(document).on("click", "a[href*='#']", function (evt) {
            const id = jQuery(this)[0].hash;
            if (jQuery(id).length > 0) {
                evt.preventDefault();
                if (header.classList.contains('active--mobile')) {
                    hamburger.classList.toggle('active');
                    header.classList.toggle('active--mobile');
                    navigation.classList.toggle('active');
                }
                controllerSmooth.scrollTo(id, 0.8);
            }
        });

        if (window.location.hash) {
            const hash = window.location.hash;
            controllerSmooth.scrollTo(hash, 0.1);
        }
        /** ******** End Smooth Anchor Links ********** */
    }

    stickySidebar() {
        const sticky = document.querySelector('[data-js-sticky]');

        if (!sticky) {
            return;
        }
        let controllerSticky = null;

        function initSticky() {
            controllerSticky = new ScrollMagic.Controller();
            const element = document.querySelector('.article-job');
            const footer = document.querySelector('[data-js-footer]');
            const parseHeightElement = parseFloat(getComputedStyle(element, null).height.replace("px", ""));
            const parseHeightFooter = parseFloat(getComputedStyle(footer, null).height.replace("px", ""));

            const scene = new ScrollMagic.Scene({
                triggerElement: parseHeightElement,
                triggerHook: 0.15,
                duration: parseHeightElement - parseHeightFooter + 100
            })
                .setPin("[data-js-sticky]")
                // .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                .addTo(controllerSticky);
        }

        if (isDesktop()) {
            initSticky();
        }

        window.addEventListener('resize', () => {
            if (!isDesktop()) {
                if (controllerSticky) {
                    controllerSticky = controllerSticky.destroy(true);
                }
            } else {
                if (!controllerSticky) {
                    initSticky();
                } else {
                    controllerSticky = controllerSticky.destroy(true);
                    initSticky();
                }
            }
        });

    }


    heroFullHeightShapes() {
        const FullHero = document.querySelector('.hero--full');

        if (!FullHero) {
            return;
        }

        let controllerParallax = null;

        function initParallax() {
            controllerParallax = new ScrollMagic.Controller();
            const heroShape = FullHero.querySelectorAll('.shape');
            const tl = new TimelineMax();

            for (let i = 0; i < heroShape.length; i++) {
                const RandY = getRandomInt(150, 350);

                tl.fromTo(heroShape[i], 0.2, {
                    y: 0,
                }, {
                    y: RandY,
                    ease: Linear.easeNone
                }, 0)
            }

            const scene = new ScrollMagic.Scene({
                triggerElement: FullHero,
                triggerHook: 0,
                duration: FullHero.offsetHeight
            })
                .setTween(tl)
                // .addIndicators({name: "shapes"}) // add indicators (requires plugin)
                .addTo(controllerParallax);

            if (!isMobileDevice()) {
                const tween = TweenMax.fromTo(FullHero, 0.1, {
                    backgroundPosition: '50% 0px'
                }, {
                    backgroundPosition: '50% -220px',
                    ease: Power2.easeInOut
                }, 0)

                const sceneTween = new ScrollMagic.Scene({
                    triggerElement: FullHero,
                    offset: 0,
                    triggerHook: 0,
                    duration: FullHero.offsetHeight
                })
                    .setTween(tween)
                    // .addIndicators({name: "background"}) // add indicators (requires plugin)
                    .addTo(controllerParallax);
            }

        }

        if (isDesktop()) {
            initParallax();
        }
    }

    heroPartHeightShapes() {
        const PartHero = document.querySelector('.hero--part');

        if (!PartHero) {
            return;
        }

        let controllerParallax = null;

        function initPartHero() {
            controllerParallax = new ScrollMagic.Controller();
            const heroShape = PartHero.querySelectorAll('.shape');
            const tl = new TimelineMax();

            for (let i = 0; i < heroShape.length; i++) {
                const RandY = getRandomInt(70, 250);

                tl.fromTo(heroShape[i], 0.2, {
                    y: 0,
                }, {
                    y: RandY,
                    ease: Linear.easeNone
                }, 0)
            }

            const scene = new ScrollMagic.Scene({
                triggerElement: PartHero,
                triggerHook: 0,
                duration: PartHero.offsetHeight
            })
                .setTween(tl)
                // .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                .addTo(controllerParallax);
        }


        if (isDesktop()) {
            initPartHero();
        }
    }

    additionalAnimation() {

        const media = document.querySelectorAll('.media');

        if (!media || !media.length) {
            return;
        }

        function animationPlayButton() {
            const controllerAdditional = new ScrollMagic.Controller();

            for (let i = 0; i < media.length; i++) {
                const button = media[i].querySelector('.play__icon');

                if (!button) {
                    return;
                }

                const tween = TweenMax.fromTo(button, 0.2, {
                    scale: 0.75,
                }, {
                    scale: 1,
                    ease: Power1.EaseOut
                })

                const scene = new ScrollMagic.Scene({triggerElement: media[i], triggerHook: 0.4, duration: 0})
                    .setTween(tween)
                    // .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                    .addTo(controllerAdditional);
            }

        }

        if (isDesktop()) {
            animationPlayButton();
        }

    }
}
