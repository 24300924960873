export default class {
    init() {
        if(jQuery('.footer-nav').length > 0){
            jQuery('body').on('click', '.footer-nav li a', function(e) {
            	var anchor = jQuery(this).data('anchor');
            	if (jQuery("#" + anchor).length > 0) {
	            	jQuery([document.documentElement, document.body]).animate({
				        scrollTop: jQuery("#" + anchor).offset().top - 124
				    }, 1000);
            	}
            })
        }
    }
}
