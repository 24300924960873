/** HELPERS */
import './Helpers/Polyfill';
/* Bootstrap */
import 'bootstrap';

/** STYLES */
import '../scss/Index.scss';

import 'babel-polyfill';
import Header from './_Header';
import Sliders from './_Sliders';
import SMagic from './_ScrollMagic';
import Encrypt from './_Encrypt';
import ParallaxJS from './_Parallax';
import Animations from './_Animations';
import Form from './_Form';
import Footer from './_Footer';

const header = new Header();
const slider = new Sliders();
const smagic = new SMagic();
const encrypt = new Encrypt();
const parallax = new ParallaxJS();
const animations = new Animations();
const footer = new Footer();
const form = new Form();

// const filter = new Filter();
document.addEventListener('DOMContentLoaded', () => {
    /* animation header */
    header.init();

    /* Sliders */
    slider.init();

    /* SmoothScroll Anchor Links */
    smagic.smoothscroll();

    smagic.heroFullHeightShapes();
    smagic.heroPartHeightShapes();

    /* encrypt data */
    encrypt.init();

    /* parallax js on custom image */
    parallax.init();

    animations.init();

    footer.init();

    form.init();

});

