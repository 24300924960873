export default class {
    init() {
        if(jQuery('.hero__content').length > 0){
            const elements = document.querySelectorAll(".hero__js");
            for (let element of elements) {
                element.classList.toggle("transformed-state");
            }
        }

        jQuery(document).on("click", "[data-js-anchor]", function (e) {
            e.preventDefault();
            var nextSection = jQuery(this).closest('section').next();
            jQuery([document.documentElement, document.body]).animate({
                scrollTop: jQuery(nextSection).offset().top - 124
            }, 1000, 'swing');
        });
    }
}
