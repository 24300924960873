import { isDesktop } from './Helpers/_mediaQueries';
import { isMobileDevice } from './Helpers/_helpers';

export const selectors = {
	header: '[data-js-header]',
	hamburger: '[data-js-hamburger]',
	navigation: '[data-js-nav]',
};

export default class {
	init() {
		this.header = document.querySelector(selectors.header);
		this.hamburger = document.querySelector(selectors.hamburger);
		this.navigation = document.querySelector(selectors.navigation);

		if (!this.header || !this.hamburger || !this.navigation) {
			return;
		}

		this.scrollingMenuActive();
		this.hoverMenuActive();
		this.toggleMenuMobile();
		this.toggleMenu();
	}

	toggleMenu() {
		this.hamburger.addEventListener( 'click', () => {
			this.hamburger.classList.toggle( 'active');
			this.header.classList.toggle( 'active--mobile');
			this.navigation.classList.toggle( 'active' );
		} );
	}

	/* add class active to .site-header */
	scrollingMenuActive() {
		let scrolled = window.scrollY || document.documentElement.scrollTop;

		const checkScroll = () => {
			if (scrolled > 10) {
				this.header.classList.add('active--scroll');
			} else if (scrolled < 10) {
				this.header.classList.remove('active--scroll');
			}

			if (scrolled > 200) {
				this.header.classList.add('active--scrolling');
			} else if (scrolled < 200) {
				this.header.classList.remove('active--scrolling');
			}
		};

		checkScroll();

		/* listener on scroll */
		document.addEventListener('scroll', () => {
			scrolled = window.scrollY || document.documentElement.scrollTop;
			checkScroll();
		});
	}

	hoverMenuActive() {
		this.navigation.addEventListener('mouseenter', () => {
			this.header.classList.add('active--enter');
		});
		this.navigation.addEventListener('mouseleave', () => {
			this.header.classList.remove('active--enter');
		});
	}

	/* toggle class to .site-header when mobile menu is active */
	toggleMenuMobile() {
		window.addEventListener('resize', () => {
			if (isDesktop() && (!isMobileDevice())) {
				this.header.classList.remove('active--mobile');
			} else if (this.hamburger.classList.contains('active')) {
				this.header.classList.add('active--mobile');
			}
		});

	/*	this.navigation.on('show.bs.collapse', () => {
			this.header.classList.add('active--mobile');
		});
		this.navigation.on('hide.bs.collapse', () => {
			this.header.classList.remove('active--mobile');
		});*/
	}
}
