import Swiper from 'swiper/js/swiper.js';
import { isDesktopAndBelow } from './Helpers/_mediaQueries'; // Swiper Slider

const selectors = {
    // sliderStats: '[data-js-slider-stats]',
    // sliderTimeline: '[data-js-slider-timeline]',
    // sliderEmployees: '[data-js-slider-employees]',
    // sliderFeatures: '[data-js-slider-features]',
    // sliderStages: '[data-js-slider-stages]',
    // sliderPosts: '[data-js-slider-posts]',
    // sliderGallery: '[data-js-slider-gallery]',
    sliderBlockquote: '[data-js-blockquote]',
    sliderIcons: '[data-js-icons]',
    sliderMedia: '[data-js-media]'
};

export default class {
    init() {
        // const sliderStats = document.querySelector(selectors.sliderStats);
        // const sliderTimeline = document.querySelector(selectors.sliderTimeline);
        // const sliderEmployees = document.querySelector(selectors.sliderEmployees);
        // const sliderFeatures = document.querySelector(selectors.sliderFeatures);
        // const sliderStages = document.querySelector(selectors.sliderStages);
        // const sliderPosts = document.querySelector(selectors.sliderPosts);
        // const sliderGallery = document.querySelector(selectors.sliderGallery);
        const sliderBlockquote = document.querySelector(selectors.sliderBlockquote);
        const sliderIcons = document.querySelector(selectors.sliderIcons);
        const sliderMedia = document.querySelector(selectors.sliderMedia);

        // if (sliderStats) {
        //     this.sliderStats();
        // }

        // if (sliderTimeline) {
        //     this.sliderTimeline();
        // }

        // if (sliderEmployees) {
        //     this.sliderEmployees();
        // }

        // if (sliderFeatures) {
        //     this.sliderFeatures();
        // }

        // if (sliderStages) {
        //     this.sliderStages();
        // }

        // if (sliderPosts) {
        //     this.sliderPosts();
        // }

        // if (sliderGallery) {
        //     this.sliderGallery();
        // }
        if (sliderBlockquote) {
            this.sliderBlockquote();
        }
        if (sliderMedia) {
            this.sliderMedia();
        }
        if (sliderIcons) {
            this.sliderIcons();
        }
        
        
    }

    /* slider with icons */
    sliderIcons() {
        let swiper = [];
        let init = false;

        function swiperIcons() {
            if (isDesktopAndBelow()) {
                if (init === false) {
                    init = true;
                    swiper = [].concat(new Swiper(selectors.sliderIcons, {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        watchOverflow: true,
                        loop: true,
                        navigation: {
                            nextEl: '[data-js-slider-icons-next]',
                            prevEl: '[data-js-slider-icons-prev]',
                        },
                        // pagination: {
                        //     el: '[data-js-slider-icons-pagination]',
                        //     clickable: true,
                        // },
                        breakpoints: {
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            }
                        }
                    }));
                }
            } else if (init === true) {
                for (let i = swiper.length - 1; i >= 0; i--) {
                    swiper[i].destroy();
                }
                init = false;
            }
        }

        function initSwipe() {
            swiper = Swiper;
            window.addEventListener('resize', () => {
                swiperIcons();
            });
            swiperIcons();
        }
        initSwipe();
    }

    /* slider with blockqoute */
    sliderBlockquote() {
        const swiper = new Swiper(selectors.sliderBlockquote, {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            speed: 1000,
            autoplay: {
              delay: 9000,
              disableOnInteraction: false,
            },
            navigation: {
                nextEl: '[data-js-slider-blockquote-next]',
                prevEl: '[data-js-slider-blockquote-prev]',
            },
            pagination: {
                el: '[data-js-slider-blockquote-pagination]',
                clickable: true,
            },
        });
    }

    /* slider with media */
    sliderMedia() {
        const swiper = new Swiper(selectors.sliderMedia, {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            speed: 1000,
            autoplay: {
              delay: 9000,
              disableOnInteraction: false,
            },
            pagination: {
                el: '[data-js-slider-media-pagination]',
                clickable: true,
            }
        });
    }
    



    //  /* slider with gallery */
    // sliderGallery() {
    //     let swiper = [];
    //     let init = false;

    //     function swiperGallery() {
    //         if (isDesktopAndBelow()) {
    //             if (init === false) {
    //                 init = true;
    //                 swiper = [].concat(new Swiper(selectors.sliderGallery, {
    //                     slidesPerView: 1.5,
    //                     spaceBetween: 0,
    //                     watchOverflow: true,
    //                     loop: true,
    //                     pagination: {
    //                         el: '[data-js-slider-gallery-pagination]',
    //                         clickable: true,
    //                     },
    //                     breakpoints: {
    //                         768: {
    //                             slidesPerView: 2,
    //                             spaceBetween: 0,
    //                         }
    //                     }
    //                 }));
    //             }
    //         } else if (init === true) {
    //             for (let i = swiper.length - 1; i >= 0; i--) {
    //                 swiper[i].destroy();
    //             }
    //             init = false;
    //         }
    //     }

    //     function initSwipe() {
    //         swiper = Swiper;
    //         window.addEventListener('resize', () => {
    //             swiperGallery();
    //         });
    //         swiperGallery();
    //     }
    //     initSwipe();
    // }

    // /* slider with posts */
    // sliderPosts() {
    //     const swiper = new Swiper(selectors.sliderPosts, {
    //         slidesPerView: 1,
    //         spaceBetween: 0,
    //         watchOverflow: true,
    //         loop: true,
    //         autoHeight: true,
    //         speed: 1000,
    //         autoplay: {
    //           delay: 9000,
    //           disableOnInteraction: false,
    //         },
    //         breakpoints: {
    //             768: {
    //                 slidesPerView: 2,
    //                 spaceBetween: 40,
    //             },
    //             991: {
    //                 slidesPerView: 3,
    //                 spaceBetween: 30,
    //             },
    //             1440: {
    //                 slidesPerView: 3,
    //                 spaceBetween: 25,
    //             },
    //             1600: {
    //                 slidesPerView: 3,
    //                 spaceBetween: 45,
    //             },
    //         },
    //         navigation: {
    //             nextEl: '[data-js-slider-posts-next]',
    //             prevEl: '[data-js-slider-posts-prev]',
    //         },
    //         pagination: {
    //             el: '[data-js-slider-posts-pagination]',
    //             clickable: true,
    //         },
    //     });
    // }



    // /* slider with stats */
    // sliderStats() {
    //     let swiper = [];
    //     let init = false;

    //     function swiperStats() {
    //         if (isDesktopAndBelow()) {
    //             if (init === false) {
    //                 init = true;
    //                 swiper = [].concat(new Swiper(selectors.sliderStats, {
    //                     slidesPerView: 1,
    //                     spaceBetween: 20,
    //                     watchOverflow: true,
    //                     loop: true,
    //                     navigation: {
    //                         nextEl: '[data-js-slider-stats-next]',
    //                         prevEl: '[data-js-slider-stats-prev]',
    //                     },
    //                     breakpoints: {
    //                         768: {
    //                             slidesPerView: 2,
    //                             spaceBetween: 15,
    //                         }
    //                     }
    //                 }));
    //             }
    //         } else if (init === true) {
    //             for (let i = swiper.length - 1; i >= 0; i--) {
    //                 swiper[i].destroy();
    //             }
    //             init = false;
    //         }
    //     }

    //     function initSwipe() {
    //         swiper = Swiper;
    //         window.addEventListener('resize', () => {
    //             swiperStats();
    //         });
    //         swiperStats();
    //     }
    //     initSwipe();
    // }

    // /* slider with timeline */
    // sliderTimeline() {
    //     let swiper = [];
    //     let init = false;

    //     function swiperTimeline() {
    //         if (isDesktopAndBelow()) {
    //             if (init === false) {
    //                 init = true;
    //                 swiper = [].concat(new Swiper(selectors.sliderTimeline, {
    //                     slidesPerView: 1,
    //                     spaceBetween: 0,
    //                     navigation: {
    //                         nextEl: '[data-js-slider-timeline-next]',
    //                         prevEl: '[data-js-slider-timeline-prev]',
    //                     },
    //                     pagination: {
    //                         el: '[data-js-slider-timeline-pagination]',
    //                         clickable: true,
    //                     },
    //                 }));
    //             }
    //         } else if (init === true) {
    //             for (let i = swiper.length - 1; i >= 0; i--) {
    //                 swiper[i].destroy();
    //             }
    //             init = false;
    //         }
    //     }

    //     function initSwipe() {
    //         swiper = Swiper;
    //         window.addEventListener('resize', () => {
    //             swiperTimeline();
    //         });
    //         swiperTimeline();
    //     }
    //     initSwipe();
    // }


    // /* slider with timeline */
    // sliderEmployees() {
    //     const swiper = new Swiper(selectors.sliderEmployees, {
    //         slidesPerView: 1,
    //         spaceBetween: 0,
    //         watchOverflow: true,
    //         loop: true,
    //         speed: 1000,
    //         autoplay: {
    //           delay: 9000,
    //           disableOnInteraction: false,
    //         },
    //         breakpoints: {
    //             768: {
    //                 slidesPerView: 2,
    //                 spaceBetween: 40,
    //             },
    //             991: {
    //                 slidesPerView: 3,
    //                 spaceBetween: 30,
    //             },
    //             1440: {
    //                 slidesPerView: 4,
    //                 spaceBetween: 25,
    //             },
    //             1600: {
    //                 slidesPerView: 4,
    //                 spaceBetween: 45,
    //             },
    //         },
    //         navigation: {
    //             nextEl: '[data-js-slider-employees-next]',
    //             prevEl: '[data-js-slider-employees-prev]',
    //         },
    //         pagination: {
    //             el: '[data-js-slider-employees-pagination]',
    //             clickable: true,
    //         },
    //     });
    // }


    // /* slider with features */
    // sliderFeatures() {
    //     let swiper = [];
    //     let init = false;

    //     function swiperFeatures() {
    //             if (isDesktopAndBelow()) {
    //                 if (init === false) {
    //                     init = true;
    //                     swiper = [].concat(new Swiper(selectors.sliderFeatures, {
    //                         slidesPerView: 1,
    //                         spaceBetween: 30,
    //                         loop: true,
    //                         pagination: {
    //                             el: '[data-js-slider-features-pagination]',
    //                             clickable: true,
    //                         },
    //                         breakpoints: {
    //                             768: {
    //                                 slidesPerView: 2,
    //                                 spaceBetween: 40,
    //                             }
    //                         }
    //                     }));
    //                 }
    //             } else if (init === true) {
    //                 for (let i = swiper.length - 1; i >= 0; i--) {
    //                     swiper[i].destroy();
    //                 }
    //                 init = false;
    //             }
    //     }

    //     function initSwipe() {
    //         swiper = Swiper;

    //         window.addEventListener('resize', () => {
    //             swiperFeatures();
    //         });
    //         swiperFeatures();
    //     }
    //     initSwipe();
    // }

    // /* slider with stages */
    // sliderStages() {
    //     let swiper = [];
    //     let init = false;

    //     function swiperStages() {
    //         if (isDesktopAndBelow()) {
    //             if (init === false) {
    //                 init = true;
    //                 swiper = [].concat(new Swiper(selectors.sliderStages, {
    //                     slidesPerView: 1,
    //                     spaceBetween: 30,
    //                     pagination: {
    //                         el: '[data-js-slider-stages-pagination]',
    //                         clickable: true,
    //                     },
    //                     breakpoints: {
    //                         768: {
    //                             slidesPerView: 2,
    //                             spaceBetween: 40,
    //                         }
    //                     }
    //                 }));
    //             }
    //         } else if (init === true) {
    //             for (let i = swiper.length - 1; i >= 0; i--) {
    //                 swiper[i].destroy();
    //             }
    //             init = false;
    //         }
    //     }

    //     function initSwipe() {
    //         swiper = Swiper;

    //         window.addEventListener('resize', () => {
    //             swiperStages();
    //         });
    //         swiperStages();
    //     }
    //     initSwipe();
    // }
}
