const selectors = {
    encrypt: '[data-code]',
};

export default class {
    init() {
        this.encrypt = document.querySelectorAll(selectors.encrypt);

        if (!this.encrypt || !this.encrypt.length) {
            return;
        }

        this.showData();
    }

    showData() {
        if (!this.encrypt || !this.encrypt.length) {
            return;
        }

        for (let i = 0; i < this.encrypt.length; i++) {
            this.encrypt[i].addEventListener('click', () => {
                const param = this.encrypt[i].getAttribute('data-code');
                const decrypt = window.atob(param);
                let html = '';

                if (this.encrypt[i].hasAttribute('data-js-phone')) {
                    const clearDecrypt = decrypt.replace(/[^0-9]+/g, '');
                    html = `<a href="tel:+${clearDecrypt}">${decrypt}</a>`;
                } else if (this.encrypt[i].hasAttribute('data-js-email')) {
                    html = `<a href="mailto:${decrypt}">${decrypt}</a>`;
                }
                this.encrypt[i].insertAdjacentHTML('beforebegin', html);
                this.encrypt[i].parentNode.removeChild(this.encrypt[i]);
            });
        }
    }
}
