import Parallax from 'parallax-js';

const selectors = {
    photo: '[data-js-parallax]',
};

export default class {
    init() {
        if (!selectors.photo) {
            return;
        }

        const scene = document.querySelectorAll(selectors.photo);
        for (let i = 0; i < scene.length; i++) {
            const parallaxInstance = new Parallax(scene[i], {
                relativeInput: true,
                hoverOnly: true
            });
        }
    }
}
